"use strict";

import "./components/regulus/sentry.js";
import "./components/regulus/utils.js";

import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "./components/regulus/google_analytics.js";      // Load this early to gtag is defined early
import "./components/regulus/basket_compact.js";
import "./components/regulus/basket_content.js";
import "./components/regulus/header_search.js";
import "./components/regulus/catalogue_filters.js";
import "./components/regulus/countdown_timer.js";
import "./components/regulus/page_broadcast.js";
import "./components/regulus/page_btn.js";
import "./components/regulus/page_carousel.js";
import "./components/regulus/page_csrf.js";
import "./components/regulus/page_reload.js";
import "./components/regulus/page_tooltip.js";
import "./components/regulus/recaptcha.js";
import "./components/regulus/facebook_pixel.js"
import "./components/regulus/voucher_form.js"
import oscar from "./components/oscar_ui.js";

$(function() {
    let $basketQuantity = $('.header-basket-quantity');
    if ($basketQuantity.text().trim()) {
        $basketQuantity.removeClass('d-none');
    } else {
        $basketQuantity.addClass('d-none');
    }

    oscar.init();
});
